<template>
  <v-card>
    <v-card-title>
      <h4 class="text-h5 font-weight-regular">
        {{ action === "edit" ? "Update" : "Create" }} {{ itemClass.title }}
      </h4>
      <v-spacer />
      <v-btn @click="$emit('dismiss')" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-divider />

    <v-form v-model="formIsValid" ref="itemForm">
      <v-card-text>
        <template v-if="itemClass.type === 'post-code'">
          <v-text-field v-model="postCode" :rules="nameRequiredRule" label="Post Code" required />
          <v-text-field v-model="division" :rules="nameRequiredRule" label="Division" required />
          <v-text-field
            v-model="divisionCode"
            :rules="nameRequiredRule"
            label="Division Code"
            required
          />
          <v-text-field v-model="district" :rules="nameRequiredRule" label="District" required />
          <v-text-field
            v-model="districtCode"
            :rules="nameRequiredRule"
            label="District Code"
            required
          />
          <v-text-field v-model="thana" :rules="nameRequiredRule" label="Thana" required />
          <v-text-field v-model="thanaCode" :rules="nameRequiredRule" label="Thana Code" required />
          <v-text-field v-model="subOffice" label="Sub-office" />
        </template>
        <template v-else-if="itemClass.type === 'profession'">
          <v-text-field v-model="category" :rules="nameRequiredRule" label="Category" required />
          <v-text-field
            v-model="subCategory"
            :rules="nameRequiredRule"
            label="Sub Category"
            required
          />
          <v-text-field v-model="score" :rules="requiredRules" label="Score" required />
        </template>
        <template v-else-if="itemClass.type === 'address'">
          <v-text-field v-model="name" :rules="nameRequiredRule" label="Name" required />

          <v-select
            v-model="areaType"
            :rules="requiredRules"
            :items="['Rural', 'Urban', 'NULL']"
            label="Area Type"
          />
          <v-text-field v-model="code" :rules="requiredRules" label="Code" required />
          <v-text-field v-model="parent" :rules="requiredRules" label="Parent" required />
          <v-text-field v-model="selfLevel" :rules="requiredRules" label="Self Level" required />
          <v-text-field v-model="status" :rules="requiredRules" label="Status" required />
        </template>
        <template v-else-if="itemClass.type === 'terms'">
          <v-text-field v-model="channel" readonly :rules="requiredRules" label="Channel" />
          <v-textarea
            v-model="terms"
            :rules="requiredRules"
            label="Terms and Conditions"
            rows="10"
            outlined
            required
            auto-grow
            style="font-size: 14px; font-family: monospace"
          />
        </template>
        <template v-else>
          <v-text-field v-model="name" :rules="nameRequiredRule" label="Name" required />
          <v-text-field v-model="score" :rules="requiredRules" label="Score" required />
        </template>
      </v-card-text>

      <v-divider />

      <v-card-actions class="px-5 py-2 lighten-4">
        <v-spacer />
        <v-btn @click="saveRole" color="success" class="px-5">
          Submit <v-icon class="ml-2 mb-1">mdi-send</v-icon>
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { REPO_ITEM_CREATE, REPO_ITEM_UPDATE } from "@/constants/api";

export default {
  name: "RepoItemForm",
  props: {
    action: {
      type: String,
      required: false,
      default: () => "edit",
    },
    item: {
      type: Object,
      required: false,
    },
    itemClass: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      formIsValid: false,
      name: null,
      score: null,
      category: null,
      subCategory: null,
      areaType: null,
      code: null,
      parent: null,
      selfLevel: null,
      status: null,
      channel: null,
      terms: null,
      postCode: null,
      division: null,
      divisionCode: null,
      district: null,
      districtCode: null,
      thana: null,
      thanaCode: null,
      subOffice: null,
      requiredRules: [(v) => (v !== undefined && v !== null) || "This field is required"],
      nameRequiredRule: [(v) => !!v || "Name field is required"],
    };
  },
  mounted() {
    if (this.action === "edit" && this.item) {
      if (this.itemClass.type === "post-code") {
        this.postCode = this.item.postCode;
        this.division = this.item.division;
        this.divisionCode = this.item.divisionCode;
        this.district = this.item.district;
        this.districtCode = this.item.districtCode;
        this.thana = this.item.thana;
        this.thanaCode = this.item.thanaCode;
        this.subOffice = this.item.subOffice;
      } else if (this.itemClass.type === "profession") {
        this.category = this.item.category;
        this.subCategory = this.item.subCategory;
        this.score = this.item.score;
      } else if (this.itemClass.type === "address") {
        this.name = this.item.name;
        this.areaType = this.item.areaType;
        this.code = this.item.code;
        this.parent = this.item.parent;
        this.selfLevel = this.item.selfLevel;
        this.status = this.item.status;
      } else {
        this.name = this.item.name;
        this.score = this.item.score;
      }
    }
  },
  methods: {
    setItemValue() {},
    getSubmitURL() {
      return (this.action === "edit" ? REPO_ITEM_UPDATE : REPO_ITEM_CREATE).replace(
        ":item:",
        this.itemClass.type
      );
    },
    saveRole() {
      this.$refs.itemForm.validate();
      if (!this.formIsValid) {
        return this.$iziToast.error({
          title: "Form is not valid",
        });
      }

      this.loading = true;
      let url = this.getSubmitURL();
      let submitData = {};

      if (this.itemClass.type === "post-code") {
        submitData = {
          postCode: this.postCode,
          division: this.division,
          divisionCode: this.divisionCode,
          district: this.district,
          districtCode: this.districtCode,
          thana: this.thana,
          thanaCode: this.thanaCode,
          subOffice: this.subOffice,
        };
      } else if (this.itemClass.type === "profession") {
        submitData = {
          category: this.category,
          subCategory: this.subCategory,
          score: this.score,
        };
      } else if (this.itemClass.type === "address") {
        submitData = {
          name: this.name,
          areaType: this.areaType,
          code: this.code,
          parent: this.parent,
          selfLevel: this.selfLevel,
          status: this.status,
        };
      } else {
        submitData = {
          name: this.name,
          score: this.score,
        };
      }

      if (this.action === "edit") {
        submitData.id = this.item.id;
      }

      this.$axios
        .post(url, submitData)
        .then((response) => {
          if (response.data.code === 0) {
            this.$emit("updated");
            this.$iziToast.success({ title: "Success" });
          } else {
            this.$iziToast.showError({ response });
          }
        })
        .catch((err) => {
          this.$iziToast.showError(err);
          if (err.response?.data?.code === 1100) {
            this.$emit("updated");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
